<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />

    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
              >Do you want to delete this {{ dataType }} ?</span
            >
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="deleteData"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center py-2>
            <v-flex xs12 sm10>
              <v-layout wrap justify-start>
                <v-flex xs6 sm3 md3 lg6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Name</span>
                  </v-flex>
                  <v-flex xs12 sm12 text-left>
                    <span class="itemValue">
                      {{ storage.name }}
                      <span v-if="!storage.name">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex align-self-center xs4 sm3 md3 lg2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="editData"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Edit </span>
                  </v-btn>
                </v-flex> 
                <v-flex align-self-center xs4 sm3 md3 lg2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="deleteDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Delete </span>
                  </v-btn>
                </v-flex>
                <v-flex align-self-center xs4 sm3 md3 lg2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="blue"
                    :ripple="false"
                    @click="
                      $router.push(
                        '/Admin/DynamicData/mitigationMethods?id=' + storage._id
                      )
                    "
                    class="itemValue"
                  >
                    <span style="color: blue"> Sub Item Details </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "deleteUrl", "dataType"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      deleteDialogue: false,
      banDialogue: false,
      activateDialogue: false,
    };
  },
  methods: {
    editData() {
      this.$emit("stepper", {
        type: "Edit",
        editData: true,
        dynamicData: this.storage,
      });
    },
    deleteData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: this.deleteUrl + this.storage._id,
        headers: {
          'x-auth-token': localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              type: "Delete",
              getData: true,
              pageResponse: response.data,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>